#t_find_device {
    border-collapse: collapse;
    width: 100%;
    max-width: 400px;
    margin-top: 10px;
}

#t_find_device td, #t_find_device th {
    border: 1px solid #ddd;
    padding: 8px;
}

#t_find_device tr:nth-child(even){background-color: #f2f2f2;}

#t_find_device th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #003B71;
    color: white;
}