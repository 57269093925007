.dashboard-content {
    position: absolute;
    top:150px;
    left: 10px;
    right: 10px;
    bottom:30px;
    overflow-y: auto;

}

.dynamic-grid {
    width: 100%;
    height: 100%;
    display: grid;
    grid-gap: 10px;
    grid-auto-rows: 300px;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    grid-template-rows: repeat(auto-fill, minmax(300px, 1fr));
    grid-auto-columns: 20px;

}

.accordion {
    margin-bottom: 10px;
    border: 2.5px solid #003B71;
}

.accordion-detail {
}