.home-container {
    position: relative;
}

.background-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 145px;
    overflow: hidden;
    z-index: -1;
}

.App-title {
    font-size: 15px;
    font-weight: bold;
    padding-left: 15px;
    padding-bottom: 10px;
    color: rgb(0, 43, 97);
    position: relative;
    margin-top: 10px;
}

.background-gradient {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0.65;
    z-index: 2;
}

.image-background {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    z-index: 1;
}

.home-content {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 10;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    overflow-y: auto;
}

.header-content {
    padding: 10px 15px;
    color: #fff;
}

.vl {
    border-left: 1px solid whitesmoke;
}

.background-logo {
    width: 4em;
    height: auto;
    vertical-align: middle;
}

.buttons-container {
    margin: -20px;
    margin-top: 20px;
}

.maps-container {
    margin: -20px;
    margin-top: 20px;
    margin-bottom: -120px;
}

.home-button {
    color: #000;
    background-color: #fff;
    display: inline-block;

    height: 110px;
    width: calc(50vw - 80px);
    min-width: 80px;

    margin: 20px;
    margin-bottom: 15px;
    margin-top: 0px;
    border-radius: 15px;
    box-shadow: 5px 5px #88888860;
    padding: 10px;
    text-align: center;
    vertical-align: middle;
}

.feature-icon {
    margin-top: 5px;
    height: 60px;
    width: auto;
}

.button-text {
    margin-top: 15px;
    font-size: 12px;
}