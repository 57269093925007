@media only screen and (max-width: 1200px) {
    div.MuiDialog-container.MuiDialog-scrollPaper div.MuiPaper-root.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm.MuiPaper-elevation24.MuiPaper-rounded {
        max-width: 100%;
        width: 92%;
        margin: 0;
    }  
    
    div.MuiDialog-root.dialog-data {
        padding: 0px !important;
    }
}

@media only screen and (min-width: 1201px) {
    div.MuiDialog-container.MuiDialog-scrollPaper div.MuiPaper-root.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm.MuiPaper-elevation24.MuiPaper-rounded {
        max-width: 100%;
        width: auto;
        margin: 0;
    }  
}